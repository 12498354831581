import { Button, Modal } from "antd";
import { entries } from "lodash";
import { useState } from "react";
import { useDeleteUser } from "../hooks/useDeleteUser";

export const DeleteUserButton = ({ id }: { id: string }) => {
  const [confirmOpened, setConfirmOpened] = useState(false);
  const { mutate: deleteUser, data: report } = useDeleteUser();

  const reportData = report?.data?.userObfuscation?.report;
  const listItems = entries(reportData).map(([reportPart, data]) => (
    <div
      key={reportPart}
      style={{
        color: data === "OK" ? "green" : "red",
      }}
    >
      {reportPart}
    </div>
  ));
  return (
    <>
      <Button
        size="large"
        type="link"
        data-qa-selector="deleteAhUser"
        onClick={() => setConfirmOpened(true)}
      >
        Delete User
      </Button>
      <Modal
        visible={confirmOpened}
        title="Delete AH User"
        okText="Delete"
        onOk={() => deleteUser({ id })}
        onCancel={() => setConfirmOpened(false)}
      >
        Confirming this action will remove the Autohero user information from
        our system!!
        {report?.traceId && (
          <div>
            <br />
            <em>trace.id</em>: {report.traceId}
            <br />
            <br />
            {listItems}
          </div>
        )}
      </Modal>
    </>
  );
};

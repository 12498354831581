import { useEffect } from "react";

import { Button, Row, Form } from "antd";

type FormContainerProps<T> = {
  onSubmit: (values: T) => void;
  initialValues?: Partial<T>;
  children: React.ReactNode;
  enableReinitialize?: boolean;
  loading?: boolean;
};

export const FormContainer = <T,>({
  onSubmit,
  initialValues,
  children,
  enableReinitialize = false,
  loading = false,
}: FormContainerProps<T>) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (enableReinitialize) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues]);

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={initialValues}
      onFinish={onSubmit}
      noValidate
      size="large"
    >
      {children}
      <Row justify="end">
        <Button
          size="large"
          type="link"
          htmlType="reset"
          onClick={() => form.setFieldsValue(initialValues)}
        >
          Reset
        </Button>
        <Button loading={loading} size="large" htmlType="submit" type="primary">
          Save
        </Button>
      </Row>
    </Form>
  );
};

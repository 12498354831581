import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { useUser } from "./hooks/useUser";
import { Space } from "antd";
import { UsersEditForm } from "./Forms/UsersEditForm";
import { UsersEditPageHeader } from "./UsersEditPageHeader";

export const UsersEdit = () => {
  const { userId } = useParams<"userId">();
  const { data: user } = useUser(userId!);

  return (
    <Space direction="vertical" size="middle" style={{ width: "100%" }}>
      <Helmet title="AH user Leads" />
      <UsersEditPageHeader title={user?.email} />
      <UsersEditForm id={userId} />
    </Space>
  );
};

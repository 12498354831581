import { Card, Col, Row, Form } from "antd";
import { InputField } from "../../../components";
import { PhoneInput, isValidPhoneNumber } from "./PhoneInput";
import { FormContainer } from "./FormContainer";
import { useHasPermissions, AhUserPermission } from "../../../hooks";
import { DeleteUserButton } from "./DeleteUserButton";
import { useMemo } from "react";
import { useUser } from "../hooks/useUser";
import { useUpdateUser } from "../hooks/useUpdateUser";

export type UpdateUserVM = {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
};

type UserFormProps = {
  id?: string;
};

export function UsersEditForm({ id }: UserFormProps) {
  const hasPermission = useHasPermissions();

  const { data: user, isLoading: userLoading } = useUser(id!);
  const { mutate: updateUser, isLoading: updateUserLoading } = useUpdateUser(
    id!
  );

  const userInitialValues: Partial<UpdateUserVM> = useMemo(
    () => ({
      email: user?.email,
      firstName: user?.firstName,
      lastName: user?.lastName,
      phone: user?.phone,
    }),
    [user]
  );

  return (
    <Card
      type="inner"
      title="AH user"
      style={{ width: "100%" }}
      loading={userLoading}
    >
      <FormContainer
        onSubmit={updateUser}
        initialValues={userInitialValues}
        loading={updateUserLoading}
      >
        <Row gutter={16}>
          <Col span={12}>
            <InputField name="firstName" required label="First name" />
          </Col>
          <Col span={12}>
            <InputField name="lastName" required label="Last name" />
          </Col>
          <Col span={12}>
            <InputField
              name="email"
              required
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
              ]}
              label="Email"
            />
          </Col>
          <Col span={12}>
            <Form.Item
              name="phone"
              required
              rules={[
                {
                  required: true,
                  validator: async (_, value) => {
                    const isValid = await isValidPhoneNumber(value);
                    if (!isValid) {
                      throw new Error("Invalid phone number");
                    }
                  },
                },
              ]}
              label={<span style={{ fontWeight: 400 }}>Phone:</span>}
            >
              <PhoneInput name="phone" required />
            </Form.Item>
          </Col>
        </Row>
        {hasPermission(AhUserPermission.DeleteUser) && id ? (
          <DeleteUserButton id={id} />
        ) : null}
      </FormContainer>
    </Card>
  );
}

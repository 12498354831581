import { message } from "antd";
import { useMutation, useQueryClient } from "react-query";
import { Domain, makeGqlCall } from "../../../apiClient";
import { fetchUser } from "./useUser";

const updateCustomerMutation = /* GraphQL */ `
  mutation ($id: UUID!, $user: CustomerUserUpdateProjectionInput!) {
    customer: updateCustomerUser(id: $id, user: $user) {
      id
    }
  }
`;

type UpdateUserEntity = {
  lastName: string;
  firstName: string;
  email: string;
  phone: string;
};

type UpdateUserVariables = {
  id: string;
  user: UpdateUserEntity;
};

const updateUser = makeGqlCall<unknown, UpdateUserVariables>({
  query: updateCustomerMutation,
  domain: Domain.CustomerManagement,
});

export const useUpdateUser = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation((user: UpdateUserEntity) => updateUser({ id, user }), {
    onSuccess: () => {
      queryClient.invalidateQueries(fetchUser.name);
      message.success("User was successfully updated");
    },
  });
};

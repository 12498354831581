import { useQuery } from "react-query";

import { Filter } from "../../hooks";
import { Domain, makeGqlCall } from "../../apiClient";
import { createLikeFilter, createOrFilter, FilterItem } from "../../utils";
import { PaginationVM } from "./usePagination";

export const searchUsersQuery = `
  query ($search: SearchProjection2Input!) {
    searchCustomerUsers(search: $search) {
      entities {
        id
        firstName
        lastName
        email
        phone
        verified
        enabled
        createdOn
      }
      page
      pageSize
      totalEntityCount
      totalPageCount
    }
  }
`;

export type UserEntity = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  verified: boolean;
  enabled: boolean;
  createdOn: string;
};

type SearchUsersResponse = {
  searchCustomerUsers?: {
    entities: UserEntity[];
    totalEntityCount: number;
  };
};
export type SearchUsersVariables = {
  search: {
    filter: FilterItem | null;
    sorts: typeof sorts;
    page: number;
    pageSize: number;
  };
};

export const fetchUsers = makeGqlCall<
  SearchUsersResponse,
  SearchUsersVariables
>({
  query: searchUsersQuery,
  domain: Domain.CustomerManagement,
});

const sorts = [
  {
    property: "firstName",
    direction: "ASC",
  },
  {
    property: "lastName",
    direction: "ASC",
  },
];

export const useUsers = (
  { text }: Filter<"text">,
  { page, pageSize }: PaginationVM
) => {
  const variables: SearchUsersVariables = {
    search: {
      filter: text
        ? createOrFilter([
            createLikeFilter("email", text),
            createLikeFilter("firstName", text),
            createLikeFilter("lastName", text),
          ])
        : null,
      sorts,
      page: page - 1,
      pageSize,
    },
  };
  return useQuery([fetchUsers.name, text, page, pageSize], () =>
    fetchUsers(variables).then((res) => res?.data?.searchCustomerUsers)
  );
};

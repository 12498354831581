import { useQuery } from "react-query";
import { Domain, makeGqlCall } from "../../../apiClient";
import { createEqualFilter, FilterItem } from "../../../utils";

export const CustomerAddressFragment = /* GraphQL */ `
  fragment CustomerAddressFragment on CustomerAddressProjection {
    id
    firstName
    lastName
    street
    country
    city
    houseNumber
    zipcode
  }
`;

export const CustomerFragment = /* GraphQL */ `
  fragment CustomerFragment on CustomerProjection {
    id
    billingAddress {
      ...CustomerAddressFragment
    }
    carRegistrationAddress {
      ...CustomerAddressFragment
    }
    firstName
    lastName
    email
    phone
  }
  ${CustomerAddressFragment}
`;
export const searchUserQuery = /* GraphQL */ `
  query ($search: SearchProjection2Input!) {
    customer: searchCustomerUsers(search: $search) {
      entities {
        ...CustomerFragment
      }
    }
  }
  ${CustomerFragment}
`;

export type AddressEntity = {
  firstName: string;
  lastName: string;
  street: string;
  country: string;
  city: string;
  houseNumber: string;
  zipcode: string;
};

type UserDetailsEntity = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  billingAddress: AddressEntity & {
    id: string;
  };
  carRegistrationAddress: AddressEntity & {
    id: string;
  };
};

type FetchUserResponse = {
  customer?: {
    entities: UserDetailsEntity[];
  };
};

type FetchUserVariables = {
  search: {
    filter: FilterItem | null;
    sorts: [];
    page?: number;
    pageSize: number;
  };
};

export const fetchUser = makeGqlCall<FetchUserResponse, FetchUserVariables>({
  query: searchUserQuery,
  domain: Domain.CustomerManagement,
});

export const useUser = (id: string) => {
  return useQuery(
    [fetchUser.name, id],
    () =>
      fetchUser({
        search: {
          filter: createEqualFilter("id", id),
          pageSize: 1,
          sorts: [],
        },
      }).then((res) => res?.data?.customer?.entities[0]),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    }
  );
};

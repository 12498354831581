import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

export type Filter<T extends string> = Record<T, string>;

export const useFilter = <T extends string>(
  initialValue: Partial<Filter<T>>
) => {
  const [search, setSearch] = useSearchParams();

  const values = Object.keys(initialValue).reduce(
    (prev, curr) => ({
      ...prev,
      [curr]: search.get(curr),
    }),
    {} as Filter<T>
  );

  // return new reference only when values change
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filter = useMemo(() => values, [JSON.stringify(values)]);

  const setFilter = useCallback(
    (newFilter: Partial<Filter<T>>) => {
      Object.entries<string | undefined>(initialValue).forEach(
        ([key, initialValue]) => {
          const value = newFilter[key as T] ?? initialValue;
          if (!value) {
            search.delete(key);
          } else if (value !== filter[key as T]) {
            search.set(key, value);
          }
        }
      );
      setSearch(search, { replace: true });
    },
    [filter, initialValue, search, setSearch]
  );

  const updateFilter = (newFilter: Partial<Filter<T>>) => {
    setFilter({ ...filter, ...newFilter });
  };
  const resetFilter = () => setFilter(initialValue);
  return [filter, updateFilter, resetFilter] as const;
};

import { flow, replace } from "lodash/fp";

const ESCAPE_SYMBOL = "\\";
const escapeForLikeFilter = flow(
  replace(/[_%\\]/g, `${ESCAPE_SYMBOL}$&`),
  (v: string) => `%${v}%`
);

enum Operation {
  Or = "or",
  Like = "like",
  And = "and",
  In = "in",
  Equal = "eq",
}
export type FilterItem =
  | {
      field: string;
      op: Operation.Like;
      value: string;
      escape: typeof ESCAPE_SYMBOL;
    }
  | {
      field: null;
      op: Operation.Or | Operation.And;
      value: Array<FilterItem>;
    }
  | {
      field: string;
      op: Operation.In;
      value: Array<string>;
    }
  | {
      field: string;
      op: Operation.Equal;
      value: string;
    };

export const createInFilter = (field: string, value: string[]): FilterItem => ({
  op: Operation.In,
  field,
  value,
});

export const createAndFilter = (value: Array<FilterItem>): FilterItem => ({
  field: null,
  op: Operation.And,
  value,
});

export const createLikeFilter = (field: string, value: string): FilterItem => ({
  field,
  op: Operation.Like,
  value: escapeForLikeFilter(value),
  escape: ESCAPE_SYMBOL,
});

export const createOrFilter = (value: Array<FilterItem>): FilterItem => ({
  field: null,
  op: Operation.Or,
  value,
});

export const createEqualFilter = (
  field: string,
  value: string
): FilterItem => ({
  field,
  value,
  op: Operation.Equal,
});

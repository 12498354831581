import { Input, InputProps } from "antd";
import { ParseError, parsePhoneNumberWithError } from "libphonenumber-js";

interface PhoneInputProps extends Omit<InputProps, "value" | "onChange"> {
  value?: string;
  onChange?: (value: string) => void;
}

const prepareValue = (value: string) => {
  const cleanValue = value.replace(/[^0-9]+/g, "");
  return `+${cleanValue}`;
};

export const PhoneInput = (props: PhoneInputProps) => {
  const { value, onChange, ...restProps } = props;

  const handleChange = (value: string) => onChange?.(prepareValue(value));

  return (
    <Input
      {...restProps}
      type="phone"
      value={prepareValue(value ?? "")}
      onChange={(e) => handleChange(e.target.value)}
    />
  );
};

export const isValidPhoneNumber = async (value: string) => {
  try {
    const phone = parsePhoneNumberWithError(value);
    return phone.isPossible();
  } catch (e) {
    if (e instanceof ParseError) {
      return false;
    }
    throw e;
  }
};

import { useQuery } from "react-query";
import { makeGqlCall } from "../apiClient";

const permissionsQuery = `query {
    me {
      effectivePermissions
    }
  }`;

type FetchPermissionsResponse = {
  me: { effectivePermissions: string[] };
};

const fetchPermissions = makeGqlCall<FetchPermissionsResponse>({
  query: permissionsQuery,
});

export const usePermissions = () =>
  useQuery("permissions", async () => {
    const { data } = await fetchPermissions();

    return data.me.effectivePermissions;
  });

import { format, parseISO } from "date-fns";

const dateFormat = "dd/MM/yyyy";
const dateWithTimeFormat = "dd/MM/yyyy HH:mm";

export const formatDate = (isoDateTime: string, withTime = false) => {
  if (!isoDateTime) return "";

  const formatTemplate = withTime ? dateWithTimeFormat : dateFormat;

  return format(parseISO(isoDateTime), formatTemplate);
};

import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";

import { Root } from "./Root";

if (__WEBPACK_DEV_SERVER__) {
  require("antd/dist/antd.less");
}

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary() {
    // Customize the root error boundary for your microfrontend here.
    return <></>;
  },
});

export const { bootstrap, mount, unmount } = lifecycles;

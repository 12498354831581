import { Permission } from "./hooks";
import { AhUserPermission } from "./hooks/useHasPermissions";
import { UsersEdit } from "./pages/UsersEdit";
import { UsersOverview } from "./pages/UsersOverview";

interface Route {
  path: string;
  component: JSX.Element;
  permissions: Permission[];
}

export const routePathByPage = {
  list: "/:lang/ah-users",
  update: "/:lang/ah-users/:userId",
};

export const routes: Array<Route> = [
  {
    path: routePathByPage.list,
    permissions: [AhUserPermission.SearchUsers],
    component: <UsersOverview />,
  },
  {
    path: routePathByPage.update,
    permissions: [AhUserPermission.UpdateUser],
    component: <UsersEdit />,
  },
];

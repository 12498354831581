import { useMutation } from "react-query";
import { Domain, makeGqlCall } from "../../../apiClient";

export const deleteAhUserQuery = /* GraphQL */ `
  mutation ($id: UUID!) {
    userObfuscation(id: $id) {
      report
    }
  }
`;

type DeleteUserResponse = {
  userObfuscation?: {
    report?: Record<string, "OK" | "FAILURE">;
  };
};

const deleteUser = makeGqlCall<DeleteUserResponse, { id: string }>({
  query: deleteAhUserQuery,
  domain: Domain.CustomerManagement,
});

export const useDeleteUser = () => useMutation(deleteUser);

import { Route, Routes } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useEffect } from "react";

import { useHasPermissions } from "./hooks";
import { routes } from "./routes";
import { message } from "antd";

const ERROR_MESSAGE = "Oops! Something went wrong.";

export const Routing = () => {
  const hasPermissions = useHasPermissions();

  const client = useQueryClient();

  useEffect(() => {
    client.setDefaultOptions({
      queries: {
        onError: (err) =>
          message.error(typeof err === "string" ? err : ERROR_MESSAGE),
      },
      mutations: {
        onError: (err) => {
          message.error(typeof err === "string" ? err : ERROR_MESSAGE);
        },
      },
    });
  }, [client]);

  return (
    <Routes>
      {routes
        .filter(({ permissions }) => hasPermissions(permissions))
        .map(({ path, component }) => (
          <Route key={path} element={component} path={path} />
        ))}
    </Routes>
  );
};

import { generatePath, useParams } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";

import { PageHeader, Button } from "antd";
import { routePathByPage } from "../../routes";
import { ReactNode } from "react";

export const UsersEditPageHeader = ({ title }: { title: ReactNode }) => {
  const { lang } = useParams<"lang">();

  return (
    <PageHeader
      title={
        <>
          <UserOutlined style={{ marginRight: 4 }} />
          <b>AH user</b> / <small>{title}</small>
        </>
      }
      extra={
        <Button
          size="large"
          type="primary"
          href={generatePath(routePathByPage.list, { lang })}
        >
          Go To List
        </Button>
      }
    />
  );
};

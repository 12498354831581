import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

export type PaginationVM = {
  page: number;
  pageSize: number;
};

export const usePagination = () => {
  const [search, setSearch] = useSearchParams();

  const page = +(search.get("page") ?? 1);
  const pageSize = +(search.get("pageSize") ?? 100);

  const pagination: PaginationVM = useMemo(
    () => ({
      page,
      pageSize,
    }),
    [page, pageSize]
  );

  const setPagination = useCallback(
    ({ page, pageSize }: PaginationVM) => {
      search.set("page", page.toString());
      search.set("pageSize", pageSize.toString());
      setSearch(search);
    },
    [setSearch, search]
  );

  return [pagination, setPagination] as const;
};

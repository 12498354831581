import { Form, Space, Button, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useEffect } from "react";

type SearchFormProps = {
  initialValues: { text: string };
  onSubmit: (data: { text: string }) => void;
};

export const SearchForm = ({ onSubmit, initialValues }: SearchFormProps) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues]);

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={initialValues}
      onFinish={onSubmit}
    >
      <Space align="end" wrap>
        <Form.Item
          name="text"
          label={<span style={{ fontWeight: 400 }}>Search Text:</span>}
        >
          <Input
            size="large"
            name="text"
            style={{ minWidth: 250 }}
            placeholder="Email, First name or Last name"
          />
        </Form.Item>
        <Form.Item>
          <Button size="large" type="primary" htmlType="submit">
            <SearchOutlined />
          </Button>
        </Form.Item>
      </Space>
    </Form>
  );
};

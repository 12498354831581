import { generatePath, useParams } from "react-router-dom";
import {
  UserOutlined,
  CheckOutlined,
  MinusOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import { SearchForm } from "./SearchForm";
import { UserEntity, useUsers } from "./useUsers";
import { useFilter, useHasPermissions } from "../../hooks";
import {
  Card,
  Space,
  Typography,
  Button,
  Table,
  Row,
  Col,
  Pagination,
  PageHeader,
} from "antd";

import { AhUserPermission } from "../../hooks/useHasPermissions";
import { formatDate } from "../../utils";
import { usePagination } from "./usePagination";
import { routePathByPage } from "../../routes";

const renderFlag = (success: boolean) =>
  success ? (
    <CheckOutlined style={{ fontSize: 24, color: "green" }} />
  ) : (
    <MinusOutlined style={{ fontSize: 24, color: "gray" }} />
  );

export const UsersOverview = () => {
  const [filter, setFilter] = useFilter({
    text: "",
  });
  const [pagination, setPagination] = usePagination();
  const { data: usersData, isLoading } = useUsers(filter, pagination);
  const { totalEntityCount: total = 0, entities: customers = [] } =
    usersData ?? {};
  const hasPermission = useHasPermissions();
  const { lang } = useParams<{ lang?: string }>();

  return (
    <Space direction="vertical" size="middle" style={{ width: "100%" }}>
      <PageHeader
        title={
          <Typography.Title style={{ margin: 0 }}>
            <UserOutlined /> AH Users
          </Typography.Title>
        }
      />
      <Card title="Search for Users">
        <SearchForm onSubmit={setFilter} initialValues={filter} />
      </Card>

      {total ? (
        <Row align="middle" justify="space-between">
          <Col>
            <Typography.Title level={4}>{total} total results</Typography.Title>
          </Col>
          <Col>
            <Pagination
              current={pagination.page}
              total={total}
              pageSize={pagination.pageSize}
              hideOnSinglePage
              onChange={(page, pageSize) =>
                setPagination({
                  pageSize,
                  page,
                })
              }
            />
          </Col>
        </Row>
      ) : null}
      <Table
        dataSource={customers}
        loading={isLoading}
        pagination={{
          total,
          current: pagination.page,
          pageSize: pagination.pageSize,
        }}
        onChange={({ pageSize, current }) =>
          setPagination({
            page: current ?? pagination.page,
            pageSize: pageSize ?? pagination.pageSize,
          })
        }
        rowKey="id"
      >
        <Table.Column
          title={<strong>#</strong>}
          dataIndex="id"
          render={(_, __, idx) => idx + 1}
        />
        <Table.Column title={<strong>ID</strong>} dataIndex="id" />
        <Table.Column<UserEntity>
          title={<strong>Name</strong>}
          dataIndex="name"
          render={(_, user) => (
            <>
              {user.firstName} {user.lastName}
            </>
          )}
        />
        <Table.Column title={<strong>Email</strong>} dataIndex="email" />
        <Table.Column title={<strong>Phone</strong>} dataIndex="phone" />
        <Table.Column
          title={<strong>Created</strong>}
          dataIndex="createdOn"
          render={formatDate}
        />
        <Table.Column
          title={<strong>Verified</strong>}
          dataIndex="verified"
          render={renderFlag}
        />
        <Table.Column
          title={<strong>Active</strong>}
          dataIndex="enabled"
          render={renderFlag}
        />
        {hasPermission(AhUserPermission.UpdateUser) && (
          <Table.Column
            title={<strong>Actions</strong>}
            dataIndex="id"
            render={(id) => (
              <Button href={`/${lang}/ah-users/${id}`} type="link">
                <EditOutlined style={{ fontSize: "20px" }} />
              </Button>
            )}
          />
        )}
      </Table>
    </Space>
  );
};

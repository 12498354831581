import { useMemo } from "react";
import { usePermissions } from "./usePermissions";

export enum AhUserPermission {
  SearchUsers = "gqls://customer.management/query/searchcustomerusers",
  UpdateUser = "gqls://customer.management/mutation/updatecustomeruser",
  DeleteUser = "gqls://customer.management/mutation/userobfuscation",
}

export type Permission = AhUserPermission;

const hasRequiredPermissions =
  (availablePermissions: string[]) => (types: string | string[]) =>
    [types].flat().some((type) => availablePermissions.includes(type));

export const useHasPermissions = () => {
  const { data: availablePermissions } = usePermissions();

  return useMemo(
    () => hasRequiredPermissions(availablePermissions ?? []),
    [availablePermissions]
  );
};

export default useHasPermissions;
